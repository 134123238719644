import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import PostItem from "../components/blog/PostItem"
import PostList from "../components/blog/PostList/styled.js"
import Pagination from "../components/blog/Pagination"
import BlogLeading from "../components/Leading"

import "./style.css"

const BlogList = props => {
  const postList = props.data.allMarkdownRemark.edges
  
  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/blog" : `/blog/page/${currentPage - 1}`
  const nextPage = `/blog/page/${currentPage + 1}`

  return (
    <Layout>
      <SEO title="Blog" />
      {isFirst && (
        <BlogLeading
          title="BEM VINDO AO MEU BLOG!"
          subtitle="Aproveite as postagens para aprender mais sobre design gráfico, webdesign e programação frontend"
        />
      )}
      <PostList>
        {postList.map(
          ({
            node: {
              frontmatter: {
                background,
                category,
                date,
                description,
                title,
                type,
              },
              timeToRead,
              fields: { slug },
            },
          }) =>
            type === "blogPost" ? (
              <PostItem
                key={slug}
                slug={slug}
                background={background}
                category={category}
                date={date}
                timeToRead={timeToRead}
                title={title}
                description={description}
              />
            ) : (
              ""
            )
        )}
      </PostList>
      <Pagination
        isFirst={isFirst}
        isLast={isLast}
        currentPage={currentPage}
        numPages={numPages}
        prevPage={prevPage}
        nextPage={nextPage}
      />
    </Layout>
  )
}

export const query = graphql`
  query PostList($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            background
            category
            date(formatString: "DD [de] MMMM [de] YYYY", locale: "pt-br")
            description
            title
            type
          }
          timeToRead
        }
      }
    }
  }
`

export default BlogList
