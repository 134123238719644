import styled from "styled-components"
import media from "styled-media-query"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const PostItemLink = styled(AniLink)`
  color: var(--main-color);
  display: flex;
  text-decoration: none;
  width: 30%;
  transition: all 150ms ease-in-out;

  &:hover,
  &:focus {
    color: var(--main-color);
    background: var(--cardHoverBg);
    border-radius: 0.25rem;
    box-shadow: 0 2px 12px #958fb318;
    outline: none;
  }

  &:hover div:first-of-type, &:focus div:first-of-type {
    transform: scale(1.05)
  }

  ${media.lessThan("large")`
    width: 45%;
    margin-bottom: 12px;
  `}

  ${media.lessThan("medium")`
    width: 100%;
    padding: 20px;
  `}

  --animationDuration: 150ms;

  animation-name: appear;

  &:nth-child(1) {
    animation-duration: calc(var(--animationDuration) * 1);
  }
  &:nth-child(2) {
    animation-duration: calc(var(--animationDuration) * 2);
  }
  &:nth-child(3) {
    animation-duration: calc(var(--animationDuration) * 3);
  }
  &:nth-child(4) {
    animation-duration: calc(var(--animationDuration) * 4);
  }
  &:nth-child(5) {
    animation-duration: calc(var(--animationDuration) * 5);
  }
  &:nth-child(6) {
    animation-duration: calc(var(--animationDuration) * 6);
  }

  @keyframes appear {
    0% {
      opacity: 0.1;
      transform: translate3d(0, -20px, 0);
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
`

export const PostItemWrapper = styled.section`
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
  flex-flow: column;
  padding: 2rem 3rem;
  width: 100%;

  ${media.lessThan("large")`
    padding: 0;
    margin-bottom: 1.3rem;
  `}
`

export const PostItemTag = styled.div`
  align-items: center;
  background: var(--${props => (props.background ? props.background : "gradient2")}
  );
  box-shadow: var(--elevation1);
  border-radius: 0.25rem;
  border-top-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
  margin-bottom: 1rem;
  color: #fff;
  display: flex;
  font-size: 1.1rem;
  font-weight: 700;
  justify-content: center;
  min-height: 90px;
  width: 100%;
  text-transform: uppercase;
  transition: all 150ms ease-in-out;

  ${media.lessThan("large")`
    text-align: center;
    line-height: 1.35;
  `}
`

export const PostItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.7rem;
`

export const PostItemDate = styled.time`
  font-size: 0.9rem;
`

export const PostItemTitle = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  margin: 0.2rem 0 0.5rem;
  color: var(--main-color);
  font-family: var(--fontSerif);
`

export const PostItemDescription = styled.p`
  margin-top: 0.5rem;
  color: var(--text-color);
  opacity: 0.8;
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.2;
`
